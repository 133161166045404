import React from 'react'
import { Helmet } from 'react-helmet'
import { StaticImage } from 'gatsby-plugin-image'
import { DynamicStaticImage } from '../components/ArtistAtSchool/dynamicStaticImage'
import { festifAtSchoolMeta, getSchoolArtistBySlug } from '../data/festif-at-school-data'

const artistAtSchoolTemplate = ({ pageContext }) => {
  const { post } = pageContext
  const artist = getSchoolArtistBySlug(post)
  const name = artist.name
  const region = artist.region
  const image = artist.getImageLocalPath
  const facebook = artist.facebook
  const instagram = artist.instagram
  const website = artist.website
  const apple = artist.apple
  const spotify = artist.spotify
  const videoId = artist.videoId
  const date = artist.date
  const description = artist.description
  const schools = artist.schools
  const sponsorLocalPath = artist.sponsor

  return (
    <div className="!relative">
        <Helmet>
            <title>Le Festif! à l'École - {name}</title>
            <meta name="description" content={festifAtSchoolMeta.description} />
            <meta name="keywords" content={festifAtSchoolMeta.keywords} />
            <meta property="og:title" content={festifAtSchoolMeta.title} />
            <meta property="og:description" content={festifAtSchoolMeta.description}/>
            <meta property="og:type" content="website" />
            <meta property="og:image" content={festifAtSchoolMeta.ogImageUrl} />
        </Helmet>

        <StaticImage
            src="../images/festifAtSchool/artists_detail/bg.png"
            alt="Background image"
            className="!absolute top-0 h-full w-full bg-[#f2e7ca]" />

        {/* MENU */}
        <div className="text-center bg-[#f2e7ca]">
            <div className="!relative lg:h-[160px] h-[75px]">
                <a className="!absolute lg:top-10 top-4 lg:left-10 left-4" href="/le-festif-a-l-ecole/">
                    <StaticImage
                        src="../images/festifAtSchool/top_logo.png"
                        alt="Le Festif! à l'école logo"
                        className="lg:w-[308px] w-[70px]" />
                </a>
                <div className="text-right">
                    <a className="!absolute lg:top-10 top-4 lg:right-10 right-4" href="/">
                        <StaticImage
                        src="../images/festifAtSchool/logo_festif.png"
                        alt="Le Festif de Baie-Saint-Paul logo"
                        className="lg:w-[230px] w-[70px]" />
                    </a>
                </div>
            </div>
        </div>

        <div className="!relative lg:max-w-[50%] max-w-[90%] lg:mx-[25%] mx-[5%]">
            <center>
                <div>
                    <a href={facebook}>
                        <StaticImage
                            src="../images/festifAtSchool/artists_detail/facebook.png"
                            alt="Facebook logo"
                            className="lg:max-h-[30px] lg:max-w-[30px] max-h-[20px] max-w-[20px] mb-2" />
                    </a>
                    <a href={instagram}>
                        <StaticImage
                            src="../images/festifAtSchool/artists_detail/instagram.png"
                            alt="Instagram logo"
                            className="lg:max-h-[30px] lg:max-w-[30px] max-h-[20px] max-w-[20px] mb-2 ml-4" />
                    </a>
                    <a href={website}>
                        <StaticImage
                            src="../images/festifAtSchool/artists_detail/web.png"
                            alt="Generic web site logo"
                            className="lg:max-h-[30px] lg:max-w-[30px] max-h-[20px] max-w-[20px] mb-2 ml-4" />
                    </a>
                    <a href={spotify}>
                        <StaticImage
                            src="../images/festifAtSchool/artists_detail/spotify.png"
                            alt="Spotify logo"
                            className="lg:max-h-[30px] lg:max-w-[30px] max-h-[20px] max-w-[20px] mb-2 ml-4" />
                    </a>
                    <a href={apple}>
                        <StaticImage
                            src="../images/festifAtSchool/artists_detail/apple.png"
                            alt="Apple logo"
                            className="lg:max-h-[30px] lg:max-w-[30px] max-h-[20px] max-w-[20px] mb-2 ml-4" />
                    </a>
                </div>
                <p className="font-poppins font-black lg:text-[79px] text-[40px] text-[#5255c6] uppercase">{name.toUpperCase()}</p>
                <p className="font-poppins lg:text-[27px] text-[20px] text-[#5255c6] tracking-[.25em] mb-4 uppercase">{region.toUpperCase()}</p>

                <DynamicStaticImage
                    localPath={image}
                    alt={name}
                    className="max-w-[920px] rounded-xl mb-6" />

                <div className="mb-8">
                    <p className="font-poppins font-black lg:text-[45px] text-[25px] text-[#5255c6] mb-2 uppercase">{date.toUpperCase()}</p>

                    {
                        schools.map((school, i) => {
                          return (<div key={i}>
                                        <p className="inline-block font-poppins font-semibold text-white lg:text-[23px] text-[18px] uppercase">{school.name.toUpperCase()} - </p>
                                        <br className="lg:hidden block"/>
                                        <p className="inline-block font-poppins font-extrabold italic text-[#5255c6] lg:text-[23px] text-[18px] uppercase">{school.city.toUpperCase()}</p>
                                    </div>)
                        })
                    }
                </div>
                <div className="mb-12">
                    {description.map((line, i) => {
                      return <p key={i} className="font-openSans text-[18px] text-white lg:text-left text-center lg:mx-36 mx-4">{line}<br/><br/></p>
                    })}
                </div>
                {(undefined !== sponsorLocalPath && sponsorLocalPath !== '') &&
                    <div className="mb-12">
                        <p className="font-poppins text-[18px] text-[#5255c6] mb-6">En collaboration avec</p>
                        <DynamicStaticImage
                            localPath={sponsorLocalPath}
                            alt="Partenaire"
                            className="max-w-[150px] max-h-[82px]" />
                    </div>
                }

                <div className="mx-4 pb-12">
                    <div className="!relative pb-[56.25%] max-w-[920px]">
                        <iframe
                            id="festif_a_lecole_bottom_youtube_player"
                            title="Title"
                            className="!absolute top-0 left-0 w-[100%] h-[100%] pb-4"
                            src={`https://www.youtube.com/embed/${videoId}`}
                            allowFullScreen="1"
                            referrerPolicy="origin-when-cross-origin" />
                    </div>
                </div>
            </center>
        </div>
    </div>
  )
}
export default artistAtSchoolTemplate
